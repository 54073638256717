import cx from 'classnames';

import styles from './section.module.scss';

interface FilterSectionProps {
  children: React.ReactNode;
  title: string;
}

export const FilterSection: React.FC<FilterSectionProps> = ({ children, title }) => {
  return (
    <div className={cx(styles.section, 'bodySmallTypography')}>
      <div className={cx(styles.title, 'bodyLargeTypography')}>{title}</div>
      {children}
    </div>
  );
};
