import { useEffect } from 'react';

import { PAGE_LAYOUT } from '~/v1/constants/elementIds';

export const useAriaHideLayout = (isHidden: boolean): void => {
  useEffect(() => {
    const main = document?.querySelector(`#${PAGE_LAYOUT}`);
    if (main) {
      main.setAttribute('aria-hidden', `${isHidden}`);
    }
  }, [isHidden]);
};
