import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';
import { type Newsletter as NewsletterType } from '~/v1/_types/Newsletter';
import { Newsletter } from '~/v1/components/newsletter/newsletter';
import { type NewsLetterId } from '~/v1/components/newsletter/newsletter.interface';

import styles from './newsletter.module.scss';

interface NewsletterModuleProps {
  id: NewsLetterId;
  newsletter: NewsletterType | null;
  children?: React.ReactNode;
  className?: string;
}

export function NewsletterModule({ id, className, newsletter, children }: NewsletterModuleProps) {
  return (
    <Grid className={cx(className ?? 'section', styles.newsletterModule)}>
      {children}
      <Column sm={5} md={3} lg={4} offsetLeft={children ? undefined : { md: 4, lg: 5 }}>
        {newsletter && (
          <Newsletter
            id={id}
            className={cx(styles.newsletter, 'overflowRight')}
            title={newsletter.description}
            disclaimer={newsletter.disclaimer}
          />
        )}
      </Column>
    </Grid>
  );
}
