'use client';

import { useState } from 'react';

import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';
import { type QuickLink } from '~/v1/_types/QuickLink';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonType } from '~/v1/components/button/button.interface';
import { InlineLink } from '~/v1/components/inlineLink/inlineLink';
import Markdown from '~/v1/components/markdown/markdown';
import { mapQuickLinks } from '~/v1/components/quickLinks/quickLinks.utils';

import styles from './links.module.scss';

interface LinksModuleProps {
  className?: string;
  title: string;
  description?: string | null;
  links?: QuickLink[];
  isNarrow?: boolean;
  linkLimit?: number;
  term?: string;
  headerClasses?: string;
}

export const LinksModule: React.FC<LinksModuleProps> = ({
  className,
  headerClasses,
  linkLimit,
  title,
  description,
  links,
  term,
  isNarrow = false,
}) => {
  const [showAll, setShowAll] = useState(false);
  const onShowAll = () => setShowAll(!showAll);
  const expandableItemCount = linkLimit && links ? links.length - linkLimit : 0;
  const data = showAll || !linkLimit ? links : links?.slice(0, linkLimit);
  const buttonText =
    links && linkLimit
      ? `See
  ${
    showAll
      ? ` fewer ${term}s`
      : ` ${links.length - linkLimit} more ${term}${expandableItemCount > 1 ? 's' : ''}`
  }`
      : '';
  return (
    <div className={className}>
      <Grid>
        <Column sm={4} md={5} lg={4} offsetLeft={{ lg: 1 }}>
          <h3 className={cx(styles.header, headerClasses)}>{title}</h3>
        </Column>

        {description && (
          <Column
            sm={5}
            md={isNarrow ? 4 : 7}
            lg={isNarrow ? 4 : 5}
            offsetLeft={{ lg: 1 }}
            className={styles.description}
          >
            <Markdown>{description}</Markdown>
          </Column>
        )}
      </Grid>

      {data && data.length > 0 && (
        <>
          <div
            className={cx(styles.links, {
              [styles.linksExpandable]: linkLimit,
            })}
          >
            {data.map((link, index) => (
              <InlineLink {...mapQuickLinks([link])[0]} key={index} />
            ))}
          </div>

          {links && linkLimit && expandableItemCount > 0 && (
            <Grid className={styles.button}>
              <Column sm={4} md={5} lg={4} offsetLeft={{ lg: 1 }}>
                <Button
                  type={ButtonType.Secondary}
                  mode={ButtonMode.Light}
                  onClick={onShowAll}
                  aria-expanded={showAll}
                  text={buttonText}
                />
              </Column>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};
