import { useEffect, useId, useRef, useState } from 'react';

import cx from 'classnames';
// TODO: Upgrade swiper (we're like 4 major versions behind)
// @ts-expect-error the version of swiper we use doesn't have types that work with TS 5+
import { Keyboard, Navigation, Pagination, type SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './carousel.module.scss';
import { Icon } from '../icons/icon';
import { IconType } from '../icons/icon.interfaces';

export type CarouselProps = {
  title?: string | null;
  slidesPerView?: number;
  spaceBetween?: number;
  children: React.ReactNode[];
  className?: string;
  testId?: string;
  buttonClasses?: string;
  slideClasses?: string;
  pagination?: SwiperOptions['pagination'];
  headerClasses?: string;
  isTransparent?: boolean;
};

export const Carousel: React.FC<CarouselProps> = ({
  title,
  slidesPerView,
  spaceBetween,
  children,
  className,
  testId,
  buttonClasses,
  slideClasses,
  pagination,
  headerClasses,
  isTransparent,
}) => {
  const [navigationHidden, setNavigationHidden] = useState(false);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const id = useId();

  useEffect(() => {
    const btnWrapper = buttonsRef.current;
    if (!btnWrapper) {
      return;
    }

    const onResize = () => {
      const prevDisabled = btnWrapper.children[0].hasAttribute('disabled');
      const nextDisabled = btnWrapper.children[1].hasAttribute('disabled');
      setNavigationHidden(prevDisabled && nextDisabled);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.root}>
      <div className={cx(styles.header, headerClasses)}>
        {title && <div className="modulesTitleTypography">{title}</div>}
        <div
          ref={buttonsRef}
          className={cx(
            styles.buttons,
            'light-background',
            { [styles.buttonsHidden]: navigationHidden },
            buttonClasses,
          )}
        >
          <button className={styles.button} id={`${id}-prev`} aria-label="Carousel previous arrow">
            <Icon type={IconType.ArrowLeft} className={styles.icon} />
          </button>
          <button className={styles.button} id={`${id}-next`} aria-label="Carousel next arrow">
            <Icon type={IconType.ArrowRight} className={styles.icon} />
          </button>
        </div>
      </div>

      <Swiper
        navigation={{
          // We have to use an attribute selector (e.g. [id="xyz"]) instead of
          //   an id selector (e.g. #xyz) because the value provided by useId()
          //   is not valid CSS.
          prevEl: `[id="${id}-prev"]`,
          nextEl: `[id="${id}-next"]`,
        }}
        keyboard={{
          enabled: true,
        }}
        pagination={pagination}
        modules={[Navigation, Pagination, Keyboard]}
        slidesPerView={slidesPerView || 'auto'}
        spaceBetween={spaceBetween || 0}
        className={cx(styles.swiper, className)}
      >
        {children.map((child, index) => (
          <SwiperSlide key={index} className={cx(styles.slide, slideClasses)} data-test-id={testId}>
            <div
              className={cx(styles.slideOverlay, {
                [styles[
                  `overlay-${index + 1}${index === 3 && children.length === 4 ? '--highlight' : ''}`
                ]]: !isTransparent,
              })}
            >
              {child}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
