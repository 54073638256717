import { type FormEventHandler } from 'react';

import { type SortId } from '~/v1/components/filter/filter.interface';

import styles from './radio.module.scss';
import { useFilterContext } from '../../filter/filter.context';

interface FieldRadioGroupProps {
  children: React.ReactNode;
}

export const FieldRadioGroup: React.FC<FieldRadioGroupProps> = ({ children }) => {
  const { onSortUpdate } = useFilterContext();

  const onRadioChange: FormEventHandler<HTMLFieldSetElement> = e => {
    const { value } = e.target as HTMLFormElement;

    return onSortUpdate({ id: value as SortId, name: value });
  };

  return (
    <fieldset className={styles.fieldset} onChange={onRadioChange}>
      {children}
    </fieldset>
  );
};
