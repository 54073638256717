import Head from 'next/head';

import { AnalyticsModule } from '~/ui/components/analytics';
import { Column } from '~/ui/components/grid';
import { type MMUF } from '~/v1/_types/MMUF';
import { type MMUFQuery } from '~/v1/_types/MMUFQuery';
import { CallToAction } from '~/v1/components/callToAction/callToAction';
import { HighlightCard } from '~/v1/components/cards/highlight/highlight';
import { mapHighlightCard } from '~/v1/components/cards/highlight/highlight.utils';
import { FilterCategory } from '~/v1/components/filter/filter.interface';
import { encodeFilters } from '~/v1/components/filter/filter.utils';
import { Link } from '~/v1/components/link/link';
import { NewsLetterId } from '~/v1/components/newsletter/newsletter.interface';
import { QuickLinks } from '~/v1/components/quickLinks/quickLinks';
import { mapQuickLinks } from '~/v1/components/quickLinks/quickLinks.utils';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';
import { AccordionTextModule } from '~/v1/modules/accordion/text/text';
import { CarouselModule } from '~/v1/modules/carousel/carousel';
import { NewsCarouselModule } from '~/v1/modules/carousel/newsCarousel/newsCarousel';
import { ContactModule } from '~/v1/modules/contact/contact';
import { RelatedGridModule } from '~/v1/modules/grid/related/related';
import { HeaderModule } from '~/v1/modules/header';
import { LinksModule } from '~/v1/modules/links/links';
import { NewsletterModule } from '~/v1/modules/newsletter/newsletter';
import { QuoteModule } from '~/v1/modules/quote/quote';
import { SpotlightModule } from '~/v1/modules/spotlight/spotlight';
import { TextModule } from '~/v1/modules/text/text';

import styles from './mmuf.module.scss';
import { NEWS_TYPES } from '../news/filters/filters';

export interface MMUFContainerProps {
  data: MMUFQuery;
}

const LOGIN_URL = 'https://www.mmuf.org/user/login?current=front';

export const MMUFContainer: React.FC<MMUFContainerProps> = ({ data }) => {
  const {
    title,
    introDescription,
    heroImage,
    articleTitle,
    articleText,
    historyLink,
    quickLinks,
    spotlight,
    graduateInitiativesTitle,
    graduateInitiativesText,
    graduateInitiativesLink,
    atGlanceTitle,
    atGlanceCards,
    pullQuote,
    inFocusHeadline,
    inFocusContent,
    fellowStoriesCtaLabel,
    fellowStoriesMessage,
    newsletter,
    inTheNews,
    faqs,
    contactTitle,
    contactMessage,
    contactEmail,
  } = data.mmuf as MMUF;

  return (
    <>
      <Head>
        <title>MMUF - Mellon Mays Undergraduate Fellowship | Mellon Foundation</title>
        <meta name="description" content={introDescription} />
      </Head>

      <HeaderModule
        title={title}
        description={introDescription}
        image={heroImage ?? undefined}
        preheaderAction={
          <Link href={LOGIN_URL} withIcon>
            Account Login
          </Link>
        }
        isRootPage
      />

      <TextModule
        className="module"
        title="Current fellows"
        links={[
          {
            __typename: ContentType.QUICK_LINK,
            externalLink: LOGIN_URL,
            label: 'Log in to access your account',
            asset: null,
            internalLink: null,
          },
        ]}
      />

      <TextModule
        title={articleTitle}
        text={articleText}
        className="section"
        links={historyLink ? [historyLink] : undefined}
      >
        {quickLinks && !!quickLinks.links.length && (
          <QuickLinks
            eyebrow={quickLinks?.eyebrow}
            title={quickLinks?.heading}
            links={mapQuickLinks(quickLinks.links, {
              [FilterCategory.AREAS]: [{ id: 'Higher Learning' }],
            })}
            className="overflowRight"
          />
        )}
      </TextModule>

      {spotlight && <SpotlightModule spotlight={spotlight} />}

      <LinksModule
        className="section"
        title={graduateInitiativesTitle}
        description={graduateInitiativesText}
        links={[graduateInitiativesLink]}
      />

      {atGlanceCards && (
        <CarouselModule className="section" title={atGlanceTitle}>
          {atGlanceCards?.map(card => (
            <HighlightCard
              key={card.title}
              gtm={{ module: AnalyticsModule.AT_GLANCE }}
              {...mapHighlightCard(card)}
            />
          ))}
        </CarouselModule>
      )}

      {pullQuote && <QuoteModule quote={pullQuote} />}

      {!!inFocusContent.length && (
        <RelatedGridModule label={inFocusHeadline || 'MMUF in focus'} content={inFocusContent} />
      )}

      {newsletter || fellowStoriesCtaLabel || fellowStoriesMessage ? (
        <NewsletterModule id={NewsLetterId.MMUF} newsletter={newsletter}>
          <Column sm={5} md={4} lg={4} offsetLeft={{ lg: 1 }}>
            <CallToAction
              className={styles.callToAction}
              href={encodeFilters(Route.NEWS, {
                [FilterCategory.TYPES]: [NEWS_TYPES.GRANTEE],
              })}
              buttonLabel={fellowStoriesCtaLabel}
              text={fellowStoriesMessage}
            />
          </Column>
        </NewsletterModule>
      ) : null}

      {!!inTheNews.length && (
        <NewsCarouselModule
          title="Latest news"
          showButton
          ctaLabel="View all news"
          ctaUrl={Route.NEWS}
          news={inTheNews}
        />
      )}

      {faqs && <AccordionTextModule title={faqs.title} items={faqs.faqs} />}

      <ContactModule title={contactTitle} text={contactMessage} email={contactEmail} />
    </>
  );
};
