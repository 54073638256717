'use client';

import { useRef, useState } from 'react';

import cx from 'classnames';

import { COPY_CLIPBOARD_TOGGLE_TIMEOUT } from '~/v1/constants/timings';

import styles from './email.module.scss';
import { Icon } from '../icons/icon';
import { IconType } from '../icons/icon.interfaces';

interface EmailProps {
  label: string;
  className?: string;
}

export const Email: React.FC<EmailProps> = ({ label, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleTooltip = () => setIsVisible(!isVisible);
  const clipboardTimeout: { current: NodeJS.Timeout | null } = useRef(null);

  const copyToClipboard = () => {
    toggleTooltip();
    clipboardTimeout.current = setTimeout(() => {
      setIsVisible(false);
    }, COPY_CLIPBOARD_TOGGLE_TIMEOUT);

    navigator.clipboard.writeText(label);
  };

  return (
    <div className={cx(styles.email, className)}>
      {isVisible && <button onClick={toggleTooltip} className={styles.backdrop} />}
      <button
        onClick={copyToClipboard}
        className={cx(styles.emailButton, {
          [styles.active]: isVisible,
        })}
      >
        <Icon className={styles.emailIcon} type={IconType.Envelope} />
        <span className="buttonTypography">{label}</span>
      </button>

      {isVisible && (
        <span
          role="alert"
          className={cx('buttonTypography', styles.tooltip, {
            [styles.tooltipVisible]: isVisible,
          })}
        >
          Link copied
        </span>
      )}
    </div>
  );
};
