import cx from 'classnames';

import { AnalyticsCard, type AnalyticsModule, Event, trackEvent } from '~/ui/components/analytics';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Link } from '~/v1/components/link/link';
import { TEST_ID } from '~/v1/constants/testId';

import { type HighlightCardType } from './highlight.interface';
import styles from './highlight.module.scss';

export interface HighlightCardProps extends HighlightCardType {
  className?: string;
  isThemed?: boolean;
  gtm: {
    module: AnalyticsModule;
  };
}

export const HighlightCard: React.FC<HighlightCardProps> = ({
  href,
  number,
  title,
  text,
  linkText,
  isThemed,
  isStatic,
  gtm,
  className,
}) => {
  const classes = cx(styles.card, className, {
    [styles.isThemed]: isThemed,
  });

  const content = (
    <div className={styles.content}>
      {number && <h2 className="sectionTitleTypography">{number}</h2>}
      <div className={cx('bodyLargeTypography', styles.label)}>{title}</div>
      {text && <p className="bodySmallTypography">{text}</p>}
    </div>
  );

  return isStatic ? (
    <div className={classes}>{content}</div>
  ) : (
    <FullBleedCTA label={text || title} className={classes} data-test-id={TEST_ID.CARD.EMPHASIZED}>
      {content}
      <FullBleedCTATrigger>
        <Link
          href={href}
          onClick={() =>
            trackEvent(Event.CARD_CLICK, {
              cardModule: gtm.module,
              cardType: AnalyticsCard.HIGHLIGHT,
              cardCTA: linkText || '',
              cardTitle: title,
            })
          }
          withIcon
        >
          {linkText}
        </Link>
      </FullBleedCTATrigger>
    </FullBleedCTA>
  );
};
