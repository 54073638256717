import cx from 'classnames';

import { useFilterContext } from '~/v1/components/filter/filter.context';

import styles from './radio.module.scss';
import { FilterQueryProp, type SortType } from '../../filter/filter.interface';

interface FieldRadioProps {
  option: SortType;
  isDefault: boolean;
}

export const FieldRadio: React.FC<FieldRadioProps> = ({ option, isDefault }) => {
  const { isFilterLayoutActive } = useFilterContext();
  const { id, name } = option;

  return (
    <div className={styles.radio}>
      <input
        className={styles.input}
        type="radio"
        name={FilterQueryProp.SORT}
        value={id}
        id={id}
        disabled={!isFilterLayoutActive}
        aria-disabled={!isFilterLayoutActive}
        defaultChecked={isDefault}
      />
      <label
        className={cx(styles.label, 'bodySmallTypography')}
        htmlFor={id}
        aria-hidden={!isFilterLayoutActive}
      >
        <span className={styles.mark} />
        {name}
      </label>
    </div>
  );
};
