import { type AtGlanceCard } from '~/v1/_types/AtGlanceCard';
import { ArticleType, AtGlanceCardType } from '~/v1/_types/globalTypes';
import { Route } from '~/v1/constants/route';

import { type HighlightCardType } from './highlight.interface';

function getHighlightCardUrl(card: AtGlanceCard): string {
  switch (card.linkType) {
    case AtGlanceCardType.ARTICLE:
      switch (card.linkEntry?.type) {
        case ArticleType.GENERIC:
          return `${Route.ARTICLE}/${card.linkEntry.slug}`;

        case ArticleType.GRANTEE:
          return `${Route.NEWS}/${card.linkEntry.slug}`;

        case ArticleType.MELLON_IN_THE_NEWS:
          return `${Route.NEWS}/${card.linkEntry.slug}`;

        case ArticleType.PAST_EVENT:
          return `${Route.EVENTS}/${card.linkEntry.slug}`;

        case ArticleType.REPORT:
          return `${Route.REPORT}/${card.linkEntry.slug}`;

        case ArticleType.VOICES:
          return `${Route.VOICES}/${card.linkEntry.slug}`;

        default:
          return '#';
      }

    case AtGlanceCardType.GRANT_MAKING_AREAS:
      return Route.GRANT_MAKING_AREAS;

    case AtGlanceCardType.GRANTS_DB_SEARCH:
      return Route.GRANT_SEARCH;

    case AtGlanceCardType.HISTORY:
      return Route.HISTORY;

    case AtGlanceCardType.MISSION:
      return Route.MISSION;

    case AtGlanceCardType.PEOPLE:
      return Route.PEOPLE;

    default:
      return '#';
  }
}

export const mapHighlightCard = (card: AtGlanceCard): HighlightCardType => {
  const noArticleReference = card.linkType === AtGlanceCardType.ARTICLE && !card.linkEntry;

  return {
    ...card,
    href: getHighlightCardUrl(card),
    linkText: card.linkText || 'Read more',
    isStatic: card.linkType === AtGlanceCardType.NONE || noArticleReference,
  };
};
