import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';

import styles from './item.module.scss';

export const SkeletonContent: React.FC = () => {
  return (
    <Column
      offsetLeft={{ lg: 1 }}
      sm={5}
      md={6}
      lg={5}
      className={cx(styles.skeleton, styles.skeletonContent)}
    >
      <div />
      <div />
      <div />
      <div />
    </Column>
  );
};

export const SkeletonImage: React.FC = () => {
  return (
    <Column
      offsetLeft={{ lg: 1 }}
      sm={1}
      md={1}
      lg={1}
      className={cx(styles.skeleton, styles.skeletonImage)}
    />
  );
};

export const ListItemSkeleton: React.FC = () => {
  return (
    <Grid>
      <SkeletonContent />
      <SkeletonImage />
    </Grid>
  );
};
