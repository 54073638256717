import { AnalyticsCard, AnalyticsContent, type AnalyticsModule } from '~/ui/components/analytics';
import {
  type AnnualReport_moreAnnualReports,
  type AnnualReport_moreAnnualReports_NestedAnnualReport,
  type AnnualReport_moreAnnualReports_OldAnnualReport,
} from '~/v1/_types/AnnualReport';
import { type AnnualReportQuery_annualReport } from '~/v1/_types/AnnualReportQuery';
import { type Financials_financialReports } from '~/v1/_types/Financials';
import { type SearchQuery_news_entities_AnnualReport } from '~/v1/_types/SearchQuery';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';

import { type GrantCardType } from './grant.interface';

export const mapGrantCard = (
  entity:
    | AnnualReport_moreAnnualReports
    | AnnualReport_moreAnnualReports_OldAnnualReport
    | AnnualReport_moreAnnualReports_NestedAnnualReport
    | AnnualReportQuery_annualReport
    | SearchQuery_news_entities_AnnualReport
    | Financials_financialReports,
  gtm: { cardModule: AnalyticsModule },
  hasLogo = false,
): GrantCardType => {
  const logo = {
    src: '/icons/logo-news.svg',
    alt: 'Mellon logo',
  };

  switch (entity.__typename) {
    case ContentType.ANNUAL_REPORT:
    case ContentType.NESTED_ANNUAL_REPORT:
      return {
        href: `${Route.ANNUAL_REPORT}/${entity.slug}`,
        title: `${entity.slug} Annual Report`,
        eyebrow: 'Report',
        linkText: `Read the President’s Letter`,
        isExternal: false,
        gtm: {
          cardModule: gtm.cardModule,
          cardType: AnalyticsCard.ANNUAL_REPORT,
          cardContentType: AnalyticsContent.ANNUAL_REPORT,
        },
        logo: hasLogo ? logo : null,
      };

    case ContentType.OLD_ANNUAL_REPORT:
      return {
        href: entity.report.src,
        title: `${entity.year} Annual Report`,
        eyebrow: 'Report',
        linkText: `Read the President’s Letter`,
        isExternal: true,
        gtm: {
          cardModule: gtm.cardModule,
          cardType: AnalyticsCard.REPORT,
          cardContentType: AnalyticsContent.REPORT,
        },
        logo: hasLogo ? logo : null,
      };

    case ContentType.MEDIA:
      return {
        href: entity.src,
        title: entity.title,
        eyebrow: 'Report',
        linkText: 'View statement',
        isExternal: true,
        gtm: {
          cardModule: gtm.cardModule,
          cardType: AnalyticsCard.REPORT,
          cardContentType: AnalyticsContent.REPORT,
        },
        logo: hasLogo ? logo : null,
      };

    default:
      throw Error(`Invalid type '${entity.__typename}'`);
  }
};
