import { AnalyticsModule } from '~/ui/components/analytics';
import { type Home_inTheNews } from '~/v1/_types/Home';
import { type MMUF_inTheNews } from '~/v1/_types/MMUF';
import { type NotFoundQuery_news_entities } from '~/v1/_types/NotFoundQuery';
import { type PersonQuery_person_relatedNews } from '~/v1/_types/PersonQuery';
import { type PressRoom_newsContent } from '~/v1/_types/PressRoom';
import { type SearchQuery_news_entities } from '~/v1/_types/SearchQuery';
import { GrantCard } from '~/v1/components/cards/grant/grant';
import { mapGrantCard } from '~/v1/components/cards/grant/grant.utils';
import { NewsCard } from '~/v1/components/cards/news/news';
import { mapNewsCard } from '~/v1/components/cards/news/news.utils';
import { ContentType } from '~/v1/constants/contentType';
import { CarouselModule } from '~/v1/modules/carousel/carousel';

import styles from './newsCarousel.module.scss';

export interface NewsCarouselModuleProps {
  news: (
    | Home_inTheNews
    | NotFoundQuery_news_entities
    | PersonQuery_person_relatedNews
    | PressRoom_newsContent
    | MMUF_inTheNews
    | SearchQuery_news_entities
  )[];
  title: string;
  showButton?: boolean;
  ctaUrl?: string;
  ctaLabel?: string;
}

export const NewsCarouselModule: React.FC<NewsCarouselModuleProps> = ({
  title,
  news,
  showButton,
  ctaUrl,
  ctaLabel,
}) => {
  return (
    <CarouselModule
      showButton={showButton}
      ctaLabel={ctaLabel}
      ctaUrl={ctaUrl}
      title={title}
      className="section"
      slideClasses={styles.slide}
    >
      {news.map(item =>
        item.__typename === ContentType.NESTED_ANNUAL_REPORT ||
        item.__typename === ContentType.ANNUAL_REPORT ? (
          <GrantCard
            className={styles.card}
            key={item.slug}
            {...mapGrantCard(item, { cardModule: AnalyticsModule.NEWS }, true)}
          />
        ) : (
          <NewsCard
            gtm={{ module: AnalyticsModule.NEWS }}
            className={styles.card}
            key={item.id}
            {...mapNewsCard(item)}
          />
        ),
      )}
    </CarouselModule>
  );
};
