import cx from 'classnames';

import { Event, trackEvent } from '~/ui/components/analytics';
import { type IconType } from '~/v1/components/icons/icon.interfaces';
import { Link } from '~/v1/components/link/link';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './quickLinks.module.scss';

export type LinkData = {
  icon?: IconType;
  link: string;
  label: string;
};

export type QuickLinkProps = {
  className?: string;
  eyebrow: string;
  title: string | null;
  links: LinkData[];
};

export const QuickLinks: React.FC<QuickLinkProps> = ({ className, eyebrow, title, links }) => {
  return (
    <div className={cx(styles.quickLinks, className)} data-test-id={TEST_ID.QUICK_LINKS}>
      <p className={cx(styles.eyebrow, 'captionTypography')}>{eyebrow}</p>
      <p className={cx(styles.title, 'bodyLargeTypography')}>{title}</p>

      <div className={styles.links}>
        {links.map((link, index) => (
          <Link
            key={index}
            href={link.link}
            onClick={() =>
              trackEvent(Event.QUICKLINK_CLICK, {
                quicklinkLink: link.link,
                quicklinkCTA: link.label,
              })
            }
            withIcon={link.icon ?? true}
            className={styles.link}
          >
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
