import { useCallback, useEffect } from 'react';

import cx from 'classnames';
import FocusTrap from 'focus-trap-react';

import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { useAriaHideLayout } from '~/v1/hooks/useAriaHideLayout';
import { usePortal } from '~/v1/hooks/usePortal';
import { useScrollLock } from '~/v1/hooks/useScrollLock';
import { LayoutClient } from '~/v1/system/layout/client/client';

import styles from './sidebar.module.scss';
import { useFilterContext } from '../filter.context';

interface FilterSidebarProps {
  children: React.ReactNode;
  className?: string;
  title: string;
}

export const FilterSidebar: React.FC<FilterSidebarProps> = ({ children, className, title }) => {
  const {
    filterPills,
    isFilterLayoutActive,
    setIsFilterLayoutActive,
    onFilterApply,
    onFilterReset,
  } = useFilterContext();
  const { ref } = useScrollLock<HTMLDivElement>(isFilterLayoutActive);

  const onCloseFilters = () => setIsFilterLayoutActive(false);

  useAriaHideLayout(isFilterLayoutActive);

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => e.code === 'Escape' && setIsFilterLayoutActive(false),
    [setIsFilterLayoutActive],
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return (
    <LayoutClient>
      {usePortal(
        <FocusTrap active={isFilterLayoutActive} focusTrapOptions={{ preventScroll: true }}>
          <div
            className={cx(styles.sidebar, className, {
              [styles.active]: isFilterLayoutActive,
            })}
            tabIndex={-1}
            aria-hidden={!isFilterLayoutActive}
            aria-disabled={!isFilterLayoutActive}
          >
            <div className={styles.inner}>
              <button
                className={styles.backdrop}
                onClick={onCloseFilters}
                aria-hidden
                aria-disabled
                tabIndex={-1}
              />
              <aside className={styles.wrapper}>
                <div className={cx(styles.header, 'modulesTitleTypography')}>
                  {filterPills.length ? `${title} (${filterPills.length})` : title}
                  <Button
                    className={styles.cancel}
                    size={ButtonSize.Icon}
                    onClick={onCloseFilters}
                    isTransparent
                    aria-label="Close filters"
                    mode={ButtonMode.Light}
                    tabIndex={isFilterLayoutActive ? 0 : -1}
                  >
                    <Icon className={styles.cancelIcon} type={IconType.Close} />
                  </Button>
                </div>
                <div className={styles.content} ref={ref}>
                  {children}
                </div>
                <div className={styles.actions}>
                  <Button
                    isAsync
                    type={ButtonType.Primary}
                    onClick={onFilterApply}
                    aria-label="View results"
                    mode={ButtonMode.Light}
                    className={styles.actionPrimary}
                    tabIndex={isFilterLayoutActive ? 0 : -1}
                  >
                    View results
                  </Button>
                  <Button
                    isAsync
                    type={ButtonType.Secondary}
                    onClick={onFilterReset}
                    aria-label="Reset filters"
                    mode={ButtonMode.Light}
                    tabIndex={isFilterLayoutActive ? 0 : -1}
                  >
                    Reset
                  </Button>
                </div>
              </aside>
            </div>
          </div>
        </FocusTrap>,
      )}
    </LayoutClient>
  );
};
