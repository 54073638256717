'use client';

import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonType } from '~/v1/components/button/button.interface';
import { Carousel } from '~/v1/components/carousel/carousel';

import styles from './carousel.module.scss';

interface CarouselModuleProps {
  className?: string;
  children: React.ReactNode[];
  title: string | null;
  ctaLabel?: string;
  ctaUrl?: string;
  showButton?: boolean;
  slideClasses?: string;
}

export const CarouselModule: React.FC<CarouselModuleProps> = ({
  className,
  children,
  title,
  ctaLabel,
  ctaUrl,
  showButton,
  slideClasses,
}) => {
  return children && children.length ? (
    <Grid className={cx('section', className)}>
      <Column sm={5} md={7} lg={8} offsetLeft={{ lg: 1 }}>
        <Carousel
          headerClasses={styles.header}
          className={cx('carousel', styles.carousel)}
          title={title}
          slideClasses={slideClasses}
        >
          {children}
        </Carousel>
        {showButton && ctaUrl && (
          <div className={styles.buttonWrapper}>
            <Button
              type={ButtonType.Secondary}
              mode={ButtonMode.Light}
              className={styles.button}
              href={ctaUrl}
              text={ctaLabel}
            />
          </div>
        )}
      </Column>
    </Grid>
  ) : null;
};
