import { type AnnualReportCard } from '~/v1/_types/AnnualReportCard';
import { type Article } from '~/v1/_types/Article';
import { getArticleCTA } from '~/v1/components/cards/article/article.utils';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';
import { formatDate } from '~/v1/utils/format';

import { type ListItemType } from './item.interface';

export const mapListItem = (entity: Article | AnnualReportCard): ListItemType => {
  switch (entity.__typename) {
    case ContentType.ANNUAL_REPORT:
      return {
        id: entity.id,
        pageLink: `${Route.ANNUAL_REPORT}/${entity.slug}`,
        title: `Annual Report ${entity.year}`,
        description: entity.description,
        captions: [entity.date ? ['Date', formatDate(entity.date)] : [null, null]].filter(Boolean),
        image: entity.introImages[0],
      };

    case ContentType.ARTICLE:
      const hasVideo = !!entity.articleBody?.includedEntries.find(
        ({ data }) => data?.__typename === ContentType.SPOTLIGHT_VIDEO,
      );

      return {
        id: entity.id,
        pageLink: entity.externalNewsUrl || getArticleCTA(entity).ctaUrl,
        title: entity.title,
        isExternal: !!entity.externalNewsUrl,
        description: entity.description,
        captions: [
          !entity.hideArticleDate && entity.articleDate
            ? ['Date', formatDate(entity.articleDate)]
            : [null, null],
        ].filter(Boolean),
        hasVideo,
        ...(!!entity.externalNewsUrl && entity.publisherLogo
          ? {
              image: {
                ...entity.publisherLogo,
                alt: entity.publisherLogo.title,
                description: entity.publisherLogo.description,
              },
            }
          : {}),
        ...(entity.articleHeroImage && !entity.externalNewsUrl
          ? {
              image: {
                ...entity.articleHeroImage,
                alt: entity.articleHeroImage.title,
                description: entity.articleHeroImage.description,
              },
            }
          : {}),
      };

    default:
      throw Error(`Invalid type '${entity.__typename}'`);
  }
};
