import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';
import { type Quote as QuoteType } from '~/v1/_types/Quote';
import { Quote } from '~/v1/components/quote/quote';

interface QuoteModuleProps {
  quote: QuoteType;
  className?: string;
}

export const QuoteModule: React.FC<QuoteModuleProps> = ({ quote, className }) => {
  return (
    <Grid className={cx('section', className)}>
      <Column
        offsetLeft={{
          sm: 0,
          md: 1,
          lg: 2,
        }}
        sm={5}
        md={5}
        lg={5}
      >
        <Quote quote={quote} />
      </Column>
    </Grid>
  );
};
