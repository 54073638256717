import { type GetStaticProps } from 'next';

import { MMUFContainer, type MMUFContainerProps } from '~/v1/containers/mmuf/mmuf';
import { getMMUF } from '~/v1/graphql/actions/server/getMMUF';
import { getTheme } from '~/v1/graphql/actions/server/getTheme';
import { Layout } from '~/v1/system/layout/layout';

type MMUFPageProps = MMUFContainerProps;

const MMUFPage: React.FC<MMUFPageProps> = ({ data }) => {
  return (
    <Layout
      navigation={data.mainNavigation}
      featuredSearches={data.featuredSearches}
      footer={data.footer}
    >
      <MMUFContainer data={data} />
    </Layout>
  );
};

export default MMUFPage;

export const getStaticProps: GetStaticProps<MMUFContainerProps> = async () => {
  const theme = await getTheme();
  const { data } = await getMMUF();

  if (!data.mmuf) {
    return {
      notFound: true,
    };
  }
  return {
    props: {
      data,
      theme,
    },
  };
};
