import { type ChangeEvent } from 'react';

import cx from 'classnames';

import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';

import styles from './checkbox.module.scss';
import { useFilterContext } from '../../filter/filter.context';
import { FilterAction, type FilterCategory, type FilterType } from '../../filter/filter.interface';

interface FieldCheckboxProps {
  className?: string;
  disabled?: boolean;
  filter: FilterType;
  linkedTo?: FilterCategory;
}

export const FieldCheckbox: React.FC<FieldCheckboxProps> = ({
  className,
  disabled,
  filter,
  linkedTo,
}) => {
  const { onFilterUpdate, isFilterActive, onFilterPropagate, isFilterLayoutActive } =
    useFilterContext();
  const isActive = isFilterActive(filter);

  const onCheckboxChange = (filter: FilterType) => (e: ChangeEvent<HTMLInputElement>) => {
    const action = e.target.checked ? FilterAction.ADD : FilterAction.REMOVE;
    const actionPropagate = e.target.checked ? FilterAction.SET : FilterAction.REMOVE;

    return linkedTo
      ? onFilterPropagate(filter, actionPropagate, linkedTo)
      : onFilterUpdate(filter, action);
  };

  return (
    <div
      className={cx(styles.checkbox, className, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        className={styles.input}
        type="checkbox"
        value={filter.id}
        id={filter.id}
        checked={isActive}
        onChange={onCheckboxChange(filter)}
        disabled={disabled || !isFilterLayoutActive}
        aria-disabled={disabled || !isFilterLayoutActive}
        aria-hidden={!isFilterLayoutActive}
      />
      <label
        className={cx(styles.label, 'bodySmallTypography', {
          [styles.active]: isActive,
        })}
        htmlFor={filter.id}
      >
        <span aria-hidden={!isFilterLayoutActive} className={styles.mark}>
          <Icon type={IconType.Tick} className={styles.icon} />
        </span>
        {filter.name}
      </label>
    </div>
  );
};
